<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
  export default {
    mounted() {
      window.onresize = () => {
        // 设置基准值
        let sUserAgent = navigator.userAgent.toLowerCase()
        let bIsWM = /mobile/.test(sUserAgent)
        let baseFontSize = 0; // 这里假设设计图宽度为375px，所以基准值为20px
        if(bIsWM) {
          baseFontSize = 98
        }else{
          baseFontSize = document.documentElement.clientWidth / 1920 * 142.087
        }
        document.documentElement.style.fontSize = `${baseFontSize}px`;
      }
    },
    destroyed() {
      localStorage.removeItem('language')
    }
  }
</script>

<style lang="less">
@import "@/assets/css/style.css";
@import "@/assets/css/common.less";
.home .home-banner-one .swiper-container .swiper-pagination .swiper-pagination-bullet {
  width: 0.05208rem;
  height: 0.05208rem;
  border: 0.01042rem solid #FFFFFF !important;
  margin: 0 0.05208rem !important;
}
.home .home-banner-one .swiper-container .swiper-pagination .swiper-pagination-bullet-active {
  background: #ffffff;
  box-shadow: 0 0 0.01042rem 0.01042rem rgba(255, 255, 255, 0.6);
}
</style>
