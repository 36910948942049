import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomeView.vue')
  },
  {
    path: '/device',
    name: 'device',
    component: () => import('@/views/DeviceView.vue')
  },
  {
    path: '/experience',
    name: 'experience',
    component: () => import('@/views/experience.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if(to.hash) {
      const hash = to.hash.substring(1)
      const element = document.getElementById(hash)
      if(element) {
        element.scrollIntoView({
          behavior: 'smooth'
        })
        return false
      }
    }
    return {x: 0, y: 0}
    // // return 期望滚动到哪个的位置
    // if (savedPosition) {
    //   return savedPosition
    // } else {
    //   // vue2.0  x  y  控制
    //   // vue3.0  left  top 控制
    //   return {x: 0, y: 0}
    // }
  }
})

export default router
